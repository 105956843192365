import React from "react"
import { graphql } from "gatsby"

import { AngledHero, ActionButton } from "@ultralight-io/components"
import { ContactForm } from "@ultralight-io/components"
import { Layout, HeroImage, NextSemester, EventsSection } from "../components"
import {compareDates} from "../utils/date-comparisons"

export default function HomePage ({data}) {
  const page = data.page.data
  const nextSemester = data.page.nextSemester
  const upcomingEvents = data.events.nodes.filter((event) => (
    compareDates(new Date(), new Date(event.data.date)) > 0
  ))

  return (
    <Layout homeActive>
      <AngledHero
        heroImage={page.heroImage && <HeroImage
          image={page.heroImage.localFile}
          alt={page.heroImage.alt}
        /> }
        titleLine1={page.headline1}
        titleLine2={page.headline2}
        tagLine={page.verse}
        // primaryActionButton={<ActionButton text="Register for Fall 2024" link="/registration" />}
        secondaryActionButton={<ActionButton text="See Fall 2024 Classes" link="/classes" secondary />}
      />
      <NextSemester data={nextSemester} />
      {upcomingEvents[0] &&
        <div className="py-12 bg-white dark:bg-gray-800">
          <EventsSection title="Next Event" events={[upcomingEvents[0]]} showTime />
        </div>
      }
      <ContactForm action="/api/contact" />
    </Layout>
  )
}

export const query = graphql`
  {
    page: wpPage(slug: {eq: "home-page"}) {
      data: acfHomePage {
        headline1
        headline2
        verse
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      nextSemester: acfNextSemester {
        heading
        classes
        concert
        cost
        description
        registration
        title
      }
    }
    events: allWpEvent(sort: {fields: acfEventData___date, order: ASC}) {
      nodes {
        title
        slug
        data: acfEventData {
          date
          description
          youtubeId
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
